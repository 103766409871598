import React from 'react';
import { Navbar as NavigateBar } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';

import 'moment/locale/ru';
import { useDispatch } from 'react-redux';

import logo3 from '../assets/logo3.svg';

import { useAuth } from '../hooks';
import { openModal } from '../store/modalSlice';
import userIcon from '../assets/userIcon.svg';

const NavBar = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleClick = () => {
    dispatch(openModal());
  };
  moment.locale('ru');
  return (
    <NavigateBar variant="dark" className="d-flex justify-content-between bg-navbar">
      <div className="ms-2">
        <img src={logo3} alt="logo"/>
      </div>
      <div className="d-flex">
        <Moment format="HH:mm" interval={1000} className="me-2 current-time" />
        <span className="current-date">{moment().format('LL')}</span>
      </div>

      <div className="d-flex flex-column align-items-center me-3" onClick={handleClick}>
        <img src={userIcon} alt="userIcon" />
        <span>{user.username}</span>
      </div>
    </NavigateBar>
  );
};

export default NavBar;