import React from 'react';

import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../store/modalSlice';
import { useAuth } from '../hooks';
import { setActiveOrder } from '../store/ordersSlice';

const Modal = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const hanldeClose = () => {
    dispatch(closeModal());
    dispatch(setActiveOrder(null));
  };
  const handleLogOut = () => {
    hanldeClose();
    auth.logOut();
  };

  const isOpened = useSelector((state) => state.modal.isOpened);

  return (
    <BootstrapModal
      show={isOpened}
      onHide={hanldeClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <BootstrapModal.Body>
        <h1 className="text-center m-4 modal-title">Выйти из системы?</h1>
        <div className="d-flex justify-content-between">
          <Button className="m-2 modal-button"  variant="danger"  onClick={hanldeClose}>Отмена</Button>
          <Button className="m-2 modal-button" variant="success"onClick={handleLogOut} >ДА</Button>
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default Modal;