import { combineReducers, configureStore } from '@reduxjs/toolkit';

import ordersReducer from './ordersSlice';
import modalSlice from './modalSlice';
import { ordersApi } from './ordersApi';





export default configureStore({
  reducer: {
    orders: ordersReducer,
    modal: modalSlice,
    [ordersApi.reducerPath] : ordersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(ordersApi.middleware),
});
