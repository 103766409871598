import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import moment from 'moment';

import { setActiveOrder, orderStatusUpdate, setTimeDelivery } from '../store/ordersSlice';
import vectorRight from '../assets/vectorRight.svg';
import { formatOrderName } from '../formatter/formatOrderName';

import CheckBox2 from './CheckBox2';

const ReadyOrder = (props) => {
  const dispatch = useDispatch();
  const timeToDelivery = useSelector((state) => state.orders.timeDeliveryOrder);
  const { order, onHandleClick } = props;
  const { orderNumber, orderStatus, id } = order;
  const activeOrder = useSelector((state) => state.orders.activeOrder);
  const timeToOrder = timeToDelivery.find((item) => item.orderNumber === orderNumber);
  const handleClick = (e) => {
    const orderNumber = e.target.value;
    const status = e.target.checked ? 6 : 5;
    const time = moment().format('HH:mm');
    dispatch(orderStatusUpdate({ orderNumber, status}));
    dispatch(setTimeDelivery({ orderNumber, time }));
  };

  const classToOrder = classNames({
    'd-flex': true,
    'justify-content-between': true,
    'align': true,
    'border-bottom': true,
    'rounded-2': true,
    'py-2': true,
    'bg-order': orderNumber === activeOrder?.orderNumber,
  });

  return (
    <div className={classToOrder}>
      <div className="mx-2" onClick={() => onHandleClick(orderNumber)} >
        <img src={vectorRight} alt="readyOrder" className="mx-2" />
        <span className="me-3 order">{orderNumber.slice(-4)}</span>
      </div>
      <div className="d-flex align-items-center mx-2">
        <div className="mx-2">
          {orderStatus === 6 ? <span className="order-time">{timeToOrder && timeToOrder.time}</span> : null}
        </div>
        <CheckBox2
          orderNumber={orderNumber}
          orderStatus={orderStatus}
          onHandleClick={handleClick}
        />
      </div>

    </div>
  );
};

const DeliveryList = () => {
  const dispatch = useDispatch();
  const onSetActiveOrder = (order) => {
    dispatch(setActiveOrder(order));
  };
  const orders = useSelector((state) => state.orders.orders);
  const readyOrders = orders
    .filter((order) => (order.orderStatus > 4 ))
    .sort((a, b) => a.orderStatus - b.orderStatus);
  const deliveredOrders = orders.filter((order) => order.orderStatus === 6);
  return (
    <>
      <div className="ready-count px-1">
        <span className="me-4">Готово:{readyOrders.length}</span>
        <span className="">Выдано:{deliveredOrders.length}</span>
      </div>

      <div className="border rounded me-3 h-100 overflow-y-scroll no-scrollbar">
        {readyOrders.map((order) => (
          <ReadyOrder
            key={order.id}
            order={order}
            onHandleClick={() => onSetActiveOrder(order)}
          />
        ))}
      </div>
    </>
  );
};

export default DeliveryList;