import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import axios from "axios";

import logo3 from "../assets/logo3.svg";
import arrow from "../assets/arrow.svg";
import eyeClosed from "../assets/eye-closed.svg";
import eyeOutline from "../assets/eye-outline.svg";
import alertTriangle from "../assets/alertTriangle.svg";

import { useAuth } from "../hooks/index";
import routes from "../routes";

const LoginPage = () => {
  const [authFailed, setAuthFailed] = useState(false);
  const [typePassword, setTypePassword] = useState("password");
  const [autoComplete, setAutoComplete] = useState("off");
  const auth = useAuth();
  const inputRef = useRef();
  const navigate = useNavigate();

  const handleChangePassword = () => {
    typePassword === "password"
      ? setTypePassword("text")
      : setTypePassword("password");
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      setAuthFailed(false);
      try {
        const { username, password } = values;
        const res = await axios(
          `http://82.209.232.62:3121/api/getUsers/${username}/${password}`
        );
        const { userName } = res.data[0] || [];
        if (userName) {
          auth.logIn({ username: userName });
          navigate(routes.mainPagePath());
        } else {
          formik.setSubmitting(true);
          setAuthFailed(true);
        }
      } catch (err) {
        formik.setSubmitting(true);

        throw err;
      }
    },
  });

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const { handleSubmit, handleChange, isSubmitting, values } = formik;

  return (
    <>
      <div className="container-fluid h-100 d-flex align-items-center align-content-center justify-content-center login">
        <img className="login logo" src={logo3} alt="logo" />
        <Form onSubmit={handleSubmit} className="container-fluid w-50">
          <h1 className="text-center mb-4 title">Вход в систему</h1>
          <Form.Group className="mb-3 pass">
            <Form.Control
              onChange={handleChange}
              value={values.username}
              placeholder="ID пользователя"
              name="username"
              id="username"
              autoComplete={autoComplete}
              required
              ref={inputRef}
              className="input"
              formNoValidate
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3 pass">
            <img
              src={typePassword === "password" ? eyeClosed : eyeOutline}
              alt=""
              className="icon-pass"
              onClick={handleChangePassword}
            />
            <Form.Control
              type={typePassword}
              onChange={handleChange}
              value={values.password}
              placeholder="пароль"
              name="password"
              id="password"
              isInvalid={authFailed}
              required
              className="input"
            />
            <Form.Control.Feedback type="invalid">
              <img src={alertTriangle} alt="" />
              <span className="ms-3">Неверный пароль, попробуйте еще раз</span>
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            type="submit"
            size="lg"
            className="mt-3 w-100 button-text"
            variant="primary"
            disabled={isSubmitting}
          >
            Вход
          </Button>
        </Form>
      </div>
      <div className="d-flex justify-content-center login footer">
        <span>TEHNO SKY Group</span>
      </div>
    </>
  );
};

export default LoginPage;
