export const formatData = (data) => {
  const upgradeData = data.reduce(
    (acc, { id, orderNumber, itemName, itemId, itemQuantity, dep, dateInsert, orderStatus, delivered }) => {
      const multipleOrderProduct = acc.find((item) => item.orderNumber === orderNumber);
      const item = {
        itemId,
        itemName,
        itemQuantity,
        delivered,
        id,
      };
      if (!multipleOrderProduct) {
        acc.push({
          id, orderNumber, orderStatus, dateInsert, data: [item],
        });
      } else {
        multipleOrderProduct.data.push(item);
      }
      return acc;
    }, []);
  return upgradeData;
};
