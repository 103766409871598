import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { formatDate } from '../formatter/formatDate';
import { formatOrderName } from '../formatter/formatOrderName';
import * as ordersSlice from '../store/ordersSlice';
import { useGetOrdersQuery } from '../store/ordersApi';
import vectorLeft from '../assets/vectorLeft.svg';

const Order = (props) => {
  const { onHandleActiveOrder, order } = props;
  const { orderNumber, dateInsert, id } = order;
  const activeOrder = useSelector((state) => state.orders.activeOrder);

  const classToOrder = classNames({
    'd-flex': true,
    'border-bottom': true,
    'rounded-2': true,
    'justify-content-between': true,
    'p-2': true,
    'bg-order': orderNumber === activeOrder?.orderNumber,
  });
  return (
    <>
      <div
        key={orderNumber}
        className={classToOrder}
        onClick={() => onHandleActiveOrder(order)}
      >
        <div className="align-center">
          <span className="order-time">{formatDate(dateInsert)}</span>
          <span className="order ms-2">{orderNumber.slice(-4)}</span>
        </div>

        <img src={vectorLeft} alt="orderToDelivery"/>
      </div>
    </>

  );
};


const PaidOrdersList = () => {
  const dispatch = useDispatch();
  const { data: fetchOrders = [], isLoading } = useGetOrdersQuery(undefined, {
    pollingInterval: 10000,
  });
  const orders = useSelector((state) => state.orders.orders);
  const activeOrder = useSelector((state) => state.orders.activeOrder);

  useEffect(() => {
    if (!isLoading) {
      dispatch(ordersSlice.setOrders(fetchOrders));
    }
  }, [dispatch, isLoading, fetchOrders]);

  const handleActiveOrder = (order) => {
    //dispatch(ordersSlice.orderStatusUpdate({ orderNumber: order.orderNumber , status: 4 }));
    localStorage.setItem('order', JSON.stringify(order));
    dispatch(ordersSlice.setActiveOrder(order));
    dispatch(ordersSlice.isAllItemsDelivered());
  };

  const ordersToDelivery = orders.filter((order) => order.orderStatus === 1 || order.orderStatus === 4);

  return (
    <div className="d-flex flex-column h-100">
      <div className=" justify-content-center ps-2 paid-title">
      </div>
      <span className="ms-2 paid-count">Кол-во заказов: {ordersToDelivery.length}</span>
      <div className="border h-100 rounded ms-2 overflow-y-scroll no-scrollbar">
        {ordersToDelivery.map((order) => (
          <Order
            key={order.id}
            order={order}
            onHandleActiveOrder={handleActiveOrder}
          />
        ))}
      </div>
    </div>
  );
};

export default PaidOrdersList;