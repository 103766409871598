export const dataToButtons = (order, hasUndelivered) => {
  if (order?.orderStatus >= 5) {
    return "orderToDelivery";
  }
  if (hasUndelivered && order?.orderStatus) {
    return "selectingOrder";
  }
  if (!hasUndelivered && order?.orderStatus) {
    return "selectedOrder";
  }
  return "chooseOrder";
};

export const buttonsVariant = (stat, actTime) => {
  if (stat === "orderToDelivery") {
    return {
      startStopButton: { color: "secondary", disabled: true },
      nextOrderButton: { color: "secondary", disabled: true },
    };
  }
  if (stat === "selectingOrder" && actTime) {
    return {
      startStopButton: { color: "danger", disabled: false },
      nextOrderButton: { color: "secondary", disabled: true },
    };
  }
  if (stat === "selectingOrder" && !actTime) {
    return {
      startStopButton: { color: "success", disabled: false },
      nextOrderButton: { color: "secondary", disabled: true },
    };
  }
  if (stat === "selectedOrder") {
    return {
      startStopButton: { color: "secondary", disabled: false },
      nextOrderButton: { color: "success", disabled: false },
    };
  }
  return {
    startStopButton: { color: "success", disabled: false },
    nextOrderButton: { color: "secondary", disabled: true },
  };
};
