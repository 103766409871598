import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { formatData } from "../formatter/formatData";

export const setOrderDeliveredAssemblyItem = createAsyncThunk(
  "order/setDelivery",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { id, deliv } = data;
      const res = await axios.get(
        `http://82.209.232.62:3121/api/orderDeliveredAssemblyItem/${id}/${deliv}`
      );
      if (res.data !== "ok") {
        throw new Error("Ошибка при изменении состояния");
      }
      dispatch(toggleDelivery({ id, deliv }));
      dispatch(isAllItemsDelivered());
    } catch (err) {
      console.log(err);
      if (err.isAxiosError) {
        throw new Error("Ошибка при изменении состояния");
      }
      return rejectWithValue(err);
    }
  }
);

export const orderStatusUpdate = createAsyncThunk(
  "order/setStatus",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { orderNumber, status } = data;
      const res = await axios.get(
        `http://82.209.232.62:3121/api/orderStatusUpdate/${orderNumber}/${status}`
      );
      if (res.status === 201) {
        dispatch(setStatus({ orderNumber, status }));
      }
    } catch (err) {
      console.log(err);
      if (err.isAxiosError) {
        throw new Error("Ошибка при изменении статуса");
      }
      return rejectWithValue(err);
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    isLoading: false,
    activeOrder: null,
    hasUndelivered: null,
    timeCompleteOrder: [],
    timeDeliveryOrder: [],
  },
  reducers: {
    setOrders: (state, { payload }) => {
      const data = formatData(payload);
      state.orders = data;
      if (!state.activeOrder) {
      }
    },
    setActiveOrder: (state, { payload }) => {
      state.activeOrder = payload;
    },
    isAllItemsDelivered: (state) => {
      state.hasUndelivered =
        state.activeOrder?.data.filter((item) => item.delivered !== 1).length >
        0;
    },
    toggleDelivery: (state, { payload }) => {
      const product = state.activeOrder.data.find(
        (item) => item.id.toString() === payload.id
      );
      product.delivered = payload.deliv;
    },
    setStatus: (state, { payload }) => {
      const findOrder = state.orders.find(
        (order) => order.orderNumber === payload.orderNumber
      );
      findOrder.orderStatus = payload.status;
    },
    setTimeComplete: (state, { payload }) => {
      state.timeCompleteOrder.push(payload);
    },
    setTimeDelivery: (state, { payload }) => {
      state.timeDeliveryOrder.push(payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setOrderDeliveredAssemblyItem.fulfilled, (state) => {
      state.isLoading = true;
    });
    builder.addCase(orderStatusUpdate.fulfilled, (state) => {
      state.isLoading = true;
    });
  },
});

export const {
  setOrders,
  setActiveOrder,
  isAllItemsDelivered,
  toggleDelivery,
  setStatus,
  setTimeComplete,
  setTimeDelivery,
} = ordersSlice.actions;

export default ordersSlice.reducer;
