import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ordersApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({ baseUrl: "http://82.209.232.62:3121/" }),
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => ({
        url: "api/gymOrdersData/1",
      }),
    }),
  }),
});

export const { useGetOrdersQuery } = ordersApi;
