import React, { useEffect, useState } from 'react';

import { Provider } from 'react-redux';

import { BrowserRouter as Router, Routes, Route, Outlet, Navigate, useNavigate } from 'react-router-dom';

import { AuthContext } from './context';
import MainPage from './components/MainPage';
import store from './store/index';
import LoginPage from './components/LoginPage';
import { useAuth } from './hooks';

import routes from './routes';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const loginedUser = JSON.parse(localStorage.getItem('userId'));
  const currentUser = loginedUser ? { username: loginedUser.username } : null;
  const [user, setUser] = useState(currentUser);

  const logIn = (data) => {
    localStorage.setItem('userId', JSON.stringify(data));
    setUser({ username: data.username });
  };

  const logOut = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('order');
    navigate(routes.loginPagePath());
    setUser(null);
  };

  const getAuthHeader = () => {
    const dataUser = JSON.parse(localStorage.getItem('userId'));
    if (dataUser && dataUser.token) {
      return { Authorization: `Bearer ${dataUser.token}` };
    }
    return {};
  };

  const value = {
    user, logIn, logOut, getAuthHeader,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const PrivateOutlet = () => {
  const auth = useAuth();
  return auth.user ? <Outlet /> : <Navigate to={routes.loginPagePath()} />;
};


const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path={routes.mainPagePath()} element={<PrivateOutlet />}>
              <Route path="" element={<MainPage />} />
            </Route>
            <Route path={routes.loginPagePath()} element={<LoginPage />} />
          </Routes>
        </AuthProvider>
      </Router>
    </Provider>


  );
};

export default App;
