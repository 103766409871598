import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { PlayFill, PauseFill } from 'react-bootstrap-icons';

import classNames from 'classnames';

import { convertTime } from '../formatter/converTime';
import { formatDate } from '../formatter/formatDate';
import { formatOrderName } from '../formatter/formatOrderName';

import {
  setOrderDeliveredAssemblyItem,
  orderStatusUpdate,
  setActiveOrder,
  setTimeComplete,
  isAllItemsDelivered,
} from '../store/ordersSlice';

import { dataToButtons, buttonsVariant } from '../formatter/buttonCreator';

import CheckBox from './CheckBox';



const OrderList = () => {
  const dispatch = useDispatch();
  const usedOrder = JSON.parse(localStorage.getItem('order'));
  const timeToOrders = JSON.parse(localStorage.getItem('timeCompleteOrders'));
  const activeOrder = useSelector((state) => state.orders.activeOrder);
  const timeToOrder = timeToOrders?.find((item) => item.orderNumber === activeOrder?.orderNumber);
  const timeCompleteOrders = useSelector((state) => state.orders.timeCompleteOrder);

  useEffect(() => {
    if(!activeOrder) {
      dispatch(setActiveOrder(usedOrder));
    }
  }, [activeOrder, dispatch, usedOrder]);

  useEffect(() => {
    dispatch(isAllItemsDelivered());
  }, []);

  useEffect(() => {
    localStorage.setItem('order', JSON.stringify(activeOrder));
    localStorage.setItem('timeCompleteOrders', JSON.stringify(timeCompleteOrders));
  }, [activeOrder, timeCompleteOrders]);

  useEffect(() => {
    if(activeOrder?.orderStatus > 4) {
      setTime(timeToOrder?.time || 0);
    } else {
      setTime(0);
    }
  }, [ activeOrder?.orderStatus, timeToOrder?.time]);

  const findOrder = activeOrder ? activeOrder : usedOrder;
  const hasUndelivered = useSelector(({ orders }) => orders.hasUndelivered);
  const [time, setTime] = useState(0);
  const [activeTimer, setActiveTimer] = useState(false);

  const stat = dataToButtons(findOrder, hasUndelivered);
  const butVar = buttonsVariant(stat, activeTimer);

  useEffect(() => {
    let intervalId;
    if (activeTimer) {
      intervalId = setInterval(() => setTime(time + 100), 1000);
    }
    return () => clearInterval(intervalId);
  }, [activeTimer, time]);

  const minutes = Math.floor((time % 360000) / 6000);
  const seconds = Math.floor((time % 6000) / 100);
  const startAndStop = () => setActiveTimer(!activeTimer);
  const reset = () => {
    setTime(0);
    setActiveTimer(false);
  };
  const handleChangeCheckBox = (e) => {
    const del = e.target.checked ? 1 : 0;
    dispatch(setOrderDeliveredAssemblyItem({ id: e.target.id, deliv: del }));
  };

  const handleClickNextOrder = () => {
    const res =!hasUndelivered ? reset : null;
    res();
    localStorage.removeItem('order');
    dispatch(orderStatusUpdate({ orderNumber: findOrder.orderNumber, status: 5 }));
    dispatch(setTimeComplete({ orderNumber: findOrder.orderNumber, time }));
    dispatch(setActiveOrder(null));
  };

  const itemsCount = findOrder?.data.reduce((acc, item) => acc + Number(item.itemQuantity), 0);
  return (
    <div className="d-flex flex-column h-100 border-bottom rounded">
      <div className="count-items"> Количество товаров в заказе: {itemsCount || 0} </div>
      <div className="border-top rounded-2">
        <div className="orderlist-title">
          {findOrder ?
            <div className="d-flex justify-content-between p-2 rounded">
              <div className="order-name">{`Заказ ${findOrder?.orderNumber.slice(-4)}`}</div>
              <div className="date-order">Поступил {formatDate(findOrder.dateInsert)}</div>
            </div>
            : null
          }
        </div>
      </div>
      <div className="overflow-y-scroll px-2 scrollbar">

        <div className="d-flex flex-column">
          {findOrder ?  findOrder.data.map(({ itemName, id, itemQuantity, delivered }) => {
            const lengthInscription = classNames({
              'shortInscription': itemName.length <= 40,
              'longInscription': itemName.length > 40,
              'd-flex': true,
              'align-items-center': true,
            });
            return (
              <div key={id}>
                <div className="d-flex p-1" >
                  <CheckBox
                    id={id}
                    itemName={itemName}
                    activeTimer={activeTimer}
                    delivered={delivered}
                    onHandleChangeCheckBox={(e) => handleChangeCheckBox(e)}
                  />
                  <div className="d-flex align-items-center px-2 item-quantity">{itemQuantity}&nbsp;шт</div>
                  <div className={lengthInscription}>{itemName}</div>
                </div>
                <hr className='m-1' />
              </div>
            );
          }) : null }
        </div>
      </div>
      <div className="mt-auto px-2 py-2">
        <div className="d-flex justify-content-between">
          <Button
            variant={butVar.startStopButton.color}
            disabled={butVar.startStopButton.disabled}
            onClick={startAndStop}
            className="startbutton"
          >
            {!activeTimer ?
              <>
                Начать
                <PlayFill />
              </>
              :
              <>
                Пауза
                < PauseFill />
              </>
            }
          </Button>
          {(!time) ? null :
            <div className="time pt-3">
              {convertTime(minutes, seconds)}
            </div>}
          <Button
            variant={butVar.nextOrderButton.color}
            disabled={butVar.nextOrderButton.disabled}
            onClick={() => handleClickNextOrder()}
            className="nextbutton"
          >
          Заказ готов
          </Button>
        </div>

      </div>
    </div>
  );
};

export default OrderList;
