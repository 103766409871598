import { useContext, useEffect, useRef } from 'react';

import { AuthContext } from '../context';

export const useAuth = () => useContext(AuthContext);


export const useInterval = (callback, delay) => {

  const savedCallback = useRef();


  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);


  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(callback, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}