import React from 'react';

const CheckBox = (props) => {
  const { id, itemName, activeTimer, delivered, onHandleChangeCheckBox } = props;
  return (
    <form>
      <label className="check">
        <input
          type="checkbox"
          className="input-check"
          id={id}
          value={itemName}
          defaultChecked={delivered}
          disabled={!activeTimer}
          onClick={onHandleChangeCheckBox}
        />
        <span></span>
      </label>
    </form>
  );
};

export default CheckBox;