import React from 'react';

import PaidOrdersList from './PaidOrdersList';
import OrderList from './OrderList';
import DeliveryList from './DeliveryList';
import Modal from './Modal';
import NavBar from './NavBar';

const MainPage = () => {

  return (
    <div className="d-flex flex-column h-100">
      <nav className="shadow-sm navbar-expand-lg navbar-light bg-white">
        <div className="">
          <NavBar />
        </div>

      </nav>
      <Modal />
      <div className="d-flex justify-content-between mt-2 row ">
        <div className="col paid-title pe-3">Оплаченные заказы</div>
        <div className="col-3 ready-orders p-0">Готовые заказы</div>
      </div>
      <div className="container-fluid h-100 overflow-hidden rounded">
        <div className="row h-100 bg-white flex-md-row">
          <div className="col col-md  flex-column pe-0 pb-3 h-100 d-flex">
            <PaidOrdersList />
          </div>
          <div className="col-7 col-md-6 p-0 pb-3 h-100">
            <OrderList />
          </div>
          <div className="col col-md ps-0 pb-3 flex-column h-100 d-flex">
            <DeliveryList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;