import React from 'react';

const CheckBox2 = (props) => {
  const { orderNumber, orderStatus, onHandleClick } = props;
  return (
    <form>
      <label className="check">
        <input
          type="checkbox"
          className="input-check"
          value={orderNumber}
          defaultChecked={orderStatus === 6}
          onClick={onHandleClick}
        />
        <span></span>
      </label>
    </form>
  );
};

export default CheckBox2;